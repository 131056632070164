//import Store from '../state/store'
const routes = [{
        path: '/dashboard',
        name: 'home',
        component: () => import('../views/pages/dashboard/index'),

    },{
        path: '/customer/list',
        name: 'customer-list',
        component: () => import('../views/pages/musteriler/list'),

    },{
        path: '/license/status',
        name: 'license-status',
        component: () => import('../views/pages/musteriler/licensestatus'),

    },{
        path: '/customer/create',
        name: 'customer-create',
        component: () => import('../views/pages/musteriler/create'),

    },{
        path: '/admin/list',
        name: 'admin-list',
        component: () => import('../views/pages/admin/list'),

    },{
        path: '/admin/create',
        name: 'admin-create',
        component: () => import('../views/pages/admin/create'),

    },{
        path: '/update/create',
        name: 'update-create',
        component: () => import('../views/pages/update/create'),

    },{
        path: '/update/list',
        name: 'update-list',
        component: () => import('../views/pages/update/list'),

    },
    {
        path: '/update/mail',
        name: 'mail-list',
        component: () => import('../views/pages/update/mail'),

    },
    {
        path: '/dist-islemleri',
        name: 'dist-islemleri',
        component: () => import('../views/pages/dist/list'),

    },
    {
        path: '/giris',
        name: 'giris',

        component: () => import('../views/pages/giris/login')
    },
    {
        path: '/profil-ayarlari',
        name: 'profil-ayarlari',

        component: () => import('../views/pages/giris/profilayarlari')
    },
    {
        path: '/sifremi-unuttum',
        name: 'sifremi-unuttum',

        component: () => import('../views/pages/giris/sifremi-unuttum')
    },
    {
        path: '/admin/eski-lisans/olustur',
        name: 'eski-lisans-olustur',

        component: () => import('../views/pages/eskilisans/olustur')
    },
    {
        path: '/admin/eski-lisans/listele',
        name: 'eski-lisans-listele',

        component: () => import('../views/pages/eskilisans/listele')
    },




]





export default routes;