import { axiosInstance } from '../../helpers/axios';
import * as Cookies from 'js-cookie';
var CryptoJS = require("crypto-js");
import router from '../../router'
export const state = {
    isAuth:false,
    token:"",
    rl: "",
    reportingpage: "",
    yetkiler:[],
    reportinglimit:10,
}

export const mutations = {

    loginSuccess(state, user){
        state.isAuth = true;
        state.token = user.token;
        state.rl = user.rl;
    },
    setToken(state, token){
        state.isAuth = true;
        state.token = token;
    },
    loginFailure (state){
        state.isAuth = false;
        state.token = "";
        state.yetkiler = [];
    },
    logout (state) {
        state.isAuth = false;
        state.token = null;
        state.yetkiler = [];
    },
    setRL(state, rl){
        state.rl = rl;
    },
    reportingPageSet(state, data){
        state.reportingpage = data;
    },
    Permission(state, data)
    {
        state.yetkiler=data
        
    },
    setReportingLimit(state,data)
    {
        state.reportinglimit = data;
    }
    
}

export const getters = {
    isAuthenticated(state) {
        if(state.token!="")
        {
            return true;
        }else{
            return false;
        }
    },
    reportingpage(state) {
        return state.reportingpage;
    },
    PermissionGet(state) {
        return state.yetkiler;
    },
    GetReportingLimitData(state)
    {
        return state.reportinglimit
    },
    rl(state) {
        var bytes  = CryptoJS.AES.decrypt(state.rl, 'MixTeknolojiSubgateHotspotcontrolTy._?2819372893791');
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return  originalText;
    },
   
   
}

export const actions = {
    
    login({ commit, dispatch }, { kullaniciadi, password } = {}) {

        return new Promise((resolve, reject) => {
        axiosInstance.post("kullanicilar/yetkili/giris", {
            email: kullaniciadi,
            password: password
        }).then(function (response) {
            if (response.data.error == true) {

                commit("loginFailure");
                reject(response.data.message);
            } else {
                commit("loginSuccess", response.data);
                axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('rl', response.data.rl);
                localStorage.setItem('user', response.data.user.username);
                localStorage.setItem("expirationDate", new Date().getTime() + +response.data.expiresIn);
                dispatch("setTimeTimer", +response.data.expiresIn);
                dispatch("PermissionAction",response.data.token);
                resolve(response.data);
            }
        }).catch(function() {
            commit("loginFailure");
            reject("Hata oluştu.Lütfen daha sonra tekrar deneyiniz");
        });
    })
        
    },
    logout({ commit }){
        commit('logout');
        Cookies.remove("vuex");
        localStorage.removeItem('token');
        localStorage.removeItem('rl');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('user');
        router.push("/giris");
    },
    setTimeTimer({ dispatch }, expiresIn) {
        setTimeout(() => {
            dispatch("logout");
        },expiresIn);
    },
    InitAuth({ commit, dispatch }) {
        let token = localStorage.getItem('token');
        if (token) {
            let expirationDate = localStorage.getItem("expirationDate");
            let rl = localStorage.getItem("rl");
            let timer = new Date().getTime();
            if (timer >= +expirationDate) {
                dispatch("logout");
            } else {
                commit("setToken", token);
                commit("setRL", rl);
                axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + token;
                let timerSecond = +expirationDate - timer;
                if (router.history._startLocation == "" || router.history._startLocation == "/")
                {
                    router.push("/dashboard")  
                } else {
                   router.push(router.history._startLocation)     
                }
                
                
                dispatch("setTimeTimer", timerSecond);
            }
            
        } else {
            dispatch('logout');
        }
    },
    async PermissionAction({ commit, getters },token2)
    {
        let token =(token2!="") ? token2 : localStorage.getItem("token");
        if (token) {
            if (getters.rl == "basicadmin")
                {
                await axiosInstance.get("kullanici/yetkili/kontrol").then((response) => {
                    commit("Permission", response.data.yetki)
                    }).catch((error) => {
                        console.log(error);
                    })  
                }

        }
    },
    ReportingPage({ commit },page) {
        commit('reportingPageSet',page);
    },
    ReportingLimitAction({commit},limit)
    {
        commit("setReportingLimit",limit);
    }
   
}



